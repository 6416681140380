<template>
  <div class="mb-2" v-if="recordItems.length">
    <div class="row">
      <div
        class="alert alert-info mb-0 overflow-auto set-page-record-items d-flex"
      >
        <div
          class="col-sm-2 new-page-record-items"
          v-for="(item, index) in recordItems"
          :key="item"
        >
          <div class="card" :class="{ 'ms-2': index > 0 }">
            <div class="card-body">
              <h5 class="card-title">
                <span
                  class="badge badge-primary rounded-pill fs-7 bg-primary text-white"
                  >{{ index + 1 }}</span
                >
                {{ pageData.name }}
              </h5>
              <p class="card-text mt-3 mb-3 text-dark">
                <i class="fa fa-clock"></i>
                {{ this.$root.dateTimeFormat(item.createDate) }}
              </p>
              <a
                :href="
                  String.format(
                    '#/set/detail/{0}/{1}',
                    this.$route.params.key,
                    item.id
                  )
                "
                target="_blank"
                class="btn btn-primary"
                ><i class="bi bi-arrow-up-right-square"></i>
                {{
                  this.$t(
                    "Set.Detail",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RecordItem",
  props: ["pageData", "recordItems"],
};
</script>
